import React from "react"
import Layout from "./src/components/Layout"
import './src/scss/base.scss'

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
)
