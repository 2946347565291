module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/fav-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ddae8361482704fe92d3e8d9fe18cbc7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"appendScript":"src/custom-sw-code.js","workboxConfig":{"importWorkboxFrom":"local","dontCacheBustURLsMatching":{},"runtimeCaching":[{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":2}},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":2}}],"skipWaiting":true,"clientsClaim":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PNGNVTW","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-cloudofertas/gatsby-browser.js'),
      options: {"plugins":[],"env":{"dev":{"siteId":"db518f1d-ef63-406d-a5b4-03cc330104df","accessToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjFhOTg3OTZjLTQxOTctNDU4NC1hYzhmLTNmMTEzYWY3ZGI3NCIsInVzZXIiOiJjODJjNDg1NS1kNDVkLTQ1MDItOGQwOC0zM2EzNTliNGUzMTAiLCJzaXRlIjoiZGI1MThmMWQtZWY2My00MDZkLWE1YjQtMDNjYzMzMDEwNGRmIiwiaWF0IjoxNjQwMTE0OTY3fQ.CK7cAmXe0elTELRBm0wE5r1WrUcgrpiMihT3-8ucPoI"},"prd":{"siteId":"db518f1d-ef63-406d-a5b4-03cc330104df","accessToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjFhOTg3OTZjLTQxOTctNDU4NC1hYzhmLTNmMTEzYWY3ZGI3NCIsInVzZXIiOiJjODJjNDg1NS1kNDVkLTQ1MDItOGQwOC0zM2EzNTliNGUzMTAiLCJzaXRlIjoiZGI1MThmMWQtZWY2My00MDZkLWE1YjQtMDNjYzMzMDEwNGRmIiwiaWF0IjoxNjQwMTE0OTY3fQ.CK7cAmXe0elTELRBm0wE5r1WrUcgrpiMihT3-8ucPoI"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/codebuild/output/src2982685361/src/src/intl","languages":["pt-BR","en"],"defaultLanguage":"pt-BR","redirect":true,"redirectDefaultLanguageToRoot":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
