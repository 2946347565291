exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tags-index-tsx": () => import("./../../../src/pages/blog/tags/index.tsx" /* webpackChunkName: "component---src-pages-blog-tags-index-tsx" */),
  "component---src-pages-cases-index-tsx": () => import("./../../../src/pages/cases/index.tsx" /* webpackChunkName: "component---src-pages-cases-index-tsx" */),
  "component---src-pages-contato-tsx": () => import("./../../../src/pages/contato.tsx" /* webpackChunkName: "component---src-pages-contato-tsx" */),
  "component---src-pages-especializacoes-amazon-api-gateway-tsx": () => import("./../../../src/pages/especializacoes/amazon-api-gateway.tsx" /* webpackChunkName: "component---src-pages-especializacoes-amazon-api-gateway-tsx" */),
  "component---src-pages-especializacoes-amazon-aurora-tsx": () => import("./../../../src/pages/especializacoes/amazon-aurora.tsx" /* webpackChunkName: "component---src-pages-especializacoes-amazon-aurora-tsx" */),
  "component---src-pages-especializacoes-amazon-cloudfront-tsx": () => import("./../../../src/pages/especializacoes/amazon-cloudfront.tsx" /* webpackChunkName: "component---src-pages-especializacoes-amazon-cloudfront-tsx" */),
  "component---src-pages-especializacoes-amazon-dynamodb-tsx": () => import("./../../../src/pages/especializacoes/amazon-dynamodb.tsx" /* webpackChunkName: "component---src-pages-especializacoes-amazon-dynamodb-tsx" */),
  "component---src-pages-especializacoes-amazon-ec-2-tsx": () => import("./../../../src/pages/especializacoes/amazon-ec2.tsx" /* webpackChunkName: "component---src-pages-especializacoes-amazon-ec-2-tsx" */),
  "component---src-pages-especializacoes-amazon-eks-tsx": () => import("./../../../src/pages/especializacoes/amazon-eks.tsx" /* webpackChunkName: "component---src-pages-especializacoes-amazon-eks-tsx" */),
  "component---src-pages-especializacoes-amazon-rds-tsx": () => import("./../../../src/pages/especializacoes/amazon-rds.tsx" /* webpackChunkName: "component---src-pages-especializacoes-amazon-rds-tsx" */),
  "component---src-pages-especializacoes-amazon-rekognition-tsx": () => import("./../../../src/pages/especializacoes/amazon-rekognition.tsx" /* webpackChunkName: "component---src-pages-especializacoes-amazon-rekognition-tsx" */),
  "component---src-pages-especializacoes-aws-cloudformation-tsx": () => import("./../../../src/pages/especializacoes/aws-cloudformation.tsx" /* webpackChunkName: "component---src-pages-especializacoes-aws-cloudformation-tsx" */),
  "component---src-pages-especializacoes-aws-cloudops-tsx": () => import("./../../../src/pages/especializacoes/aws-cloudops.tsx" /* webpackChunkName: "component---src-pages-especializacoes-aws-cloudops-tsx" */),
  "component---src-pages-especializacoes-aws-config-tsx": () => import("./../../../src/pages/especializacoes/aws-config.tsx" /* webpackChunkName: "component---src-pages-especializacoes-aws-config-tsx" */),
  "component---src-pages-especializacoes-aws-control-tower-tsx": () => import("./../../../src/pages/especializacoes/aws-control-tower.tsx" /* webpackChunkName: "component---src-pages-especializacoes-aws-control-tower-tsx" */),
  "component---src-pages-especializacoes-aws-database-migration-service-tsx": () => import("./../../../src/pages/especializacoes/aws-database-migration-service.tsx" /* webpackChunkName: "component---src-pages-especializacoes-aws-database-migration-service-tsx" */),
  "component---src-pages-especializacoes-aws-direct-connect-tsx": () => import("./../../../src/pages/especializacoes/aws-direct-connect.tsx" /* webpackChunkName: "component---src-pages-especializacoes-aws-direct-connect-tsx" */),
  "component---src-pages-especializacoes-aws-lambda-tsx": () => import("./../../../src/pages/especializacoes/aws-lambda.tsx" /* webpackChunkName: "component---src-pages-especializacoes-aws-lambda-tsx" */),
  "component---src-pages-especializacoes-aws-server-migration-service-tsx": () => import("./../../../src/pages/especializacoes/aws-server-migration-service.tsx" /* webpackChunkName: "component---src-pages-especializacoes-aws-server-migration-service-tsx" */),
  "component---src-pages-especializacoes-aws-service-catalog-tsx": () => import("./../../../src/pages/especializacoes/aws-service-catalog.tsx" /* webpackChunkName: "component---src-pages-especializacoes-aws-service-catalog-tsx" */),
  "component---src-pages-especializacoes-aws-systems-manager-tsx": () => import("./../../../src/pages/especializacoes/aws-systems-manager.tsx" /* webpackChunkName: "component---src-pages-especializacoes-aws-systems-manager-tsx" */),
  "component---src-pages-especializacoes-aws-web-application-firewall-tsx": () => import("./../../../src/pages/especializacoes/aws-web-application-firewall.tsx" /* webpackChunkName: "component---src-pages-especializacoes-aws-web-application-firewall-tsx" */),
  "component---src-pages-especializacoes-aws-well-architected-framework-tsx": () => import("./../../../src/pages/especializacoes/aws-well-architected-framework.tsx" /* webpackChunkName: "component---src-pages-especializacoes-aws-well-architected-framework-tsx" */),
  "component---src-pages-especializacoes-computing-services-tsx": () => import("./../../../src/pages/especializacoes/computing-services.tsx" /* webpackChunkName: "component---src-pages-especializacoes-computing-services-tsx" */),
  "component---src-pages-especializacoes-index-tsx": () => import("./../../../src/pages/especializacoes/index.tsx" /* webpackChunkName: "component---src-pages-especializacoes-index-tsx" */),
  "component---src-pages-especializacoes-networking-services-tsx": () => import("./../../../src/pages/especializacoes/networking-services.tsx" /* webpackChunkName: "component---src-pages-especializacoes-networking-services-tsx" */),
  "component---src-pages-especializacoes-seguranca-nuvem-aws-tsx": () => import("./../../../src/pages/especializacoes/seguranca-nuvem-aws.tsx" /* webpackChunkName: "component---src-pages-especializacoes-seguranca-nuvem-aws-tsx" */),
  "component---src-pages-especializacoes-storage-services-tsx": () => import("./../../../src/pages/especializacoes/storage-services.tsx" /* webpackChunkName: "component---src-pages-especializacoes-storage-services-tsx" */),
  "component---src-pages-imprensa-tsx": () => import("./../../../src/pages/imprensa.tsx" /* webpackChunkName: "component---src-pages-imprensa-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacidade-tsx": () => import("./../../../src/pages/privacidade.tsx" /* webpackChunkName: "component---src-pages-privacidade-tsx" */),
  "component---src-pages-quem-somos-tsx": () => import("./../../../src/pages/quem-somos.tsx" /* webpackChunkName: "component---src-pages-quem-somos-tsx" */),
  "component---src-pages-servicos-analise-profunda-audio-video-com-amazon-bedrock-index-tsx": () => import("./../../../src/pages/servicos/analise-profunda-audio-video-com-amazon-bedrock/index.tsx" /* webpackChunkName: "component---src-pages-servicos-analise-profunda-audio-video-com-amazon-bedrock-index-tsx" */),
  "component---src-pages-servicos-backup-servidor-local-para-nuvem-aws-com-cloudberry-e-amazon-s-3-index-tsx": () => import("./../../../src/pages/servicos/backup-servidor-local-para-nuvem-aws-com-cloudberry-e-amazon-s3/index.tsx" /* webpackChunkName: "component---src-pages-servicos-backup-servidor-local-para-nuvem-aws-com-cloudberry-e-amazon-s-3-index-tsx" */),
  "component---src-pages-servicos-consultoria-nuvem-aws-devops-tsx": () => import("./../../../src/pages/servicos/consultoria-nuvem-aws/devops.tsx" /* webpackChunkName: "component---src-pages-servicos-consultoria-nuvem-aws-devops-tsx" */),
  "component---src-pages-servicos-consultoria-nuvem-aws-index-tsx": () => import("./../../../src/pages/servicos/consultoria-nuvem-aws/index.tsx" /* webpackChunkName: "component---src-pages-servicos-consultoria-nuvem-aws-index-tsx" */),
  "component---src-pages-servicos-consultoria-nuvem-aws-migracao-nuvem-aws-tsx": () => import("./../../../src/pages/servicos/consultoria-nuvem-aws/migracao-nuvem-aws.tsx" /* webpackChunkName: "component---src-pages-servicos-consultoria-nuvem-aws-migracao-nuvem-aws-tsx" */),
  "component---src-pages-servicos-consultoria-nuvem-aws-reducao-custos-aws-tsx": () => import("./../../../src/pages/servicos/consultoria-nuvem-aws/reducao-custos-aws.tsx" /* webpackChunkName: "component---src-pages-servicos-consultoria-nuvem-aws-reducao-custos-aws-tsx" */),
  "component---src-pages-servicos-escala-ilimitada-para-armazenamento-onpremises-com-storage-gateway-index-tsx": () => import("./../../../src/pages/servicos/escala-ilimitada-para-armazenamento-onpremises-com-storage-gateway/index.tsx" /* webpackChunkName: "component---src-pages-servicos-escala-ilimitada-para-armazenamento-onpremises-com-storage-gateway-index-tsx" */),
  "component---src-pages-servicos-estrategia-recuperacao-desastres-on-premises-nuvem-aws-index-tsx": () => import("./../../../src/pages/servicos/estrategia-recuperacao-desastres-on-premises-nuvem-aws/index.tsx" /* webpackChunkName: "component---src-pages-servicos-estrategia-recuperacao-desastres-on-premises-nuvem-aws-index-tsx" */),
  "component---src-pages-servicos-gerenciamento-nuvem-aws-index-tsx": () => import("./../../../src/pages/servicos/gerenciamento-nuvem-aws/index.tsx" /* webpackChunkName: "component---src-pages-servicos-gerenciamento-nuvem-aws-index-tsx" */),
  "component---src-pages-servicos-index-tsx": () => import("./../../../src/pages/servicos/index.tsx" /* webpackChunkName: "component---src-pages-servicos-index-tsx" */),
  "component---src-pages-servicos-migracao-aplicacoes-web-para-nuvem-aws-index-tsx": () => import("./../../../src/pages/servicos/migracao-aplicacoes-web-para-nuvem-aws/index.tsx" /* webpackChunkName: "component---src-pages-servicos-migracao-aplicacoes-web-para-nuvem-aws-index-tsx" */),
  "component---src-pages-servicos-migracao-wordpress-para-nuvem-aws-index-tsx": () => import("./../../../src/pages/servicos/migracao-wordpress-para-nuvem-aws/index.tsx" /* webpackChunkName: "component---src-pages-servicos-migracao-wordpress-para-nuvem-aws-index-tsx" */),
  "component---src-pages-servicos-modernizacao-de-aplicacao-para-conteineres-na-nuvem-aws-index-tsx": () => import("./../../../src/pages/servicos/modernizacao-de-aplicacao-para-conteineres-na-nuvem-aws/index.tsx" /* webpackChunkName: "component---src-pages-servicos-modernizacao-de-aplicacao-para-conteineres-na-nuvem-aws-index-tsx" */),
  "component---src-pages-servicos-nuvem-aws-para-governo-index-tsx": () => import("./../../../src/pages/servicos/nuvem-aws-para-governo/index.tsx" /* webpackChunkName: "component---src-pages-servicos-nuvem-aws-para-governo-index-tsx" */),
  "component---src-pages-servicos-pagamento-nuvem-aws-em-boleto-index-tsx": () => import("./../../../src/pages/servicos/pagamento-nuvem-aws-em-boleto/index.tsx" /* webpackChunkName: "component---src-pages-servicos-pagamento-nuvem-aws-em-boleto-index-tsx" */),
  "component---src-pages-servicos-poc-ia-generativa-com-amazon-bedrock-index-tsx": () => import("./../../../src/pages/servicos/poc-ia-generativa-com-amazon-bedrock/index.tsx" /* webpackChunkName: "component---src-pages-servicos-poc-ia-generativa-com-amazon-bedrock-index-tsx" */),
  "component---src-pages-servicos-workshops-aws-immersion-days-index-tsx": () => import("./../../../src/pages/servicos/workshops-aws-immersion-days/index.tsx" /* webpackChunkName: "component---src-pages-servicos-workshops-aws-immersion-days-index-tsx" */),
  "component---src-pages-solucoes-por-setor-index-tsx": () => import("./../../../src/pages/solucoes-por-setor/index.tsx" /* webpackChunkName: "component---src-pages-solucoes-por-setor-index-tsx" */),
  "component---src-pages-solucoes-por-setor-pequenas-medias-empresas-index-tsx": () => import("./../../../src/pages/solucoes-por-setor/pequenas-medias-empresas/index.tsx" /* webpackChunkName: "component---src-pages-solucoes-por-setor-pequenas-medias-empresas-index-tsx" */),
  "component---src-pages-solucoes-por-setor-saude-index-tsx": () => import("./../../../src/pages/solucoes-por-setor/saude/index.tsx" /* webpackChunkName: "component---src-pages-solucoes-por-setor-saude-index-tsx" */),
  "component---src-pages-startup-accelerator-program-tsx": () => import("./../../../src/pages/startup-accelerator-program.tsx" /* webpackChunkName: "component---src-pages-startup-accelerator-program-tsx" */),
  "component---src-pages-trabalhe-conosco-tsx": () => import("./../../../src/pages/trabalhe-conosco.tsx" /* webpackChunkName: "component---src-pages-trabalhe-conosco-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blog-category.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blog-tag-tsx": () => import("./../../../src/templates/blog-tag.tsx" /* webpackChunkName: "component---src-templates-blog-tag-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-cases-post-tsx": () => import("./../../../src/templates/cases-post.tsx" /* webpackChunkName: "component---src-templates-cases-post-tsx" */),
  "component---src-templates-imprensa-posts-tsx": () => import("./../../../src/templates/imprensa-posts.tsx" /* webpackChunkName: "component---src-templates-imprensa-posts-tsx" */)
}

