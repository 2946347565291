import React, { Fragment, ReactNode } from 'react'

// /* STYLES */
import '../../scss/base.scss'
import '../../fonts/Montserrat/Montserrat.css'
// import Header from '../Header/header'
// import Footer from '../Footer'
import Wpp from '../FloatWpp/wpp'
import { IntlProvider } from 'gatsby-plugin-react-intl'

type Props = {
  children: ReactNode,
  path: string
  pageContext: {
    intl: {
      language: string;
      messages: any;
    };
  };
}

const Layout: React.FC<Props> = (props) => {
  return (
    <IntlProvider locale={props.pageContext.intl.language} messages={props.pageContext.intl.messages}>
      <Fragment>
        {/* <Header /> */}
        <main>
          { props.children }
        </main>
        {props.path === '/startup-accelerator-program/' || props.path === '/aws-summit-2024/' || props.path === '/aws-summit-2024/formulario/' || props.path === '/aws-summit-2024/termos-condicoes/' || props.path === '/en/aws-summit-2024/' || props.path === '/en/aws-summit-2024/formulario/' || props.path === '/en/aws-summit-2024/termos-condicoes/'
          ? ''
          : <Wpp />
        }
        {/* <Footer /> */}
      </Fragment>
    </IntlProvider>
  )
}

export default Layout
